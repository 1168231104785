"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
class User extends Base_1.Base {
    set name(n) {
        if (n) {
            let name_parts = n.split(/ /);
            switch (name_parts.length) {
                case 1:
                    this.first_name = n;
                    break;
                case 2:
                    this.first_name = name_parts[0];
                    this.last_name = name_parts[1];
                    break;
                default:
                    this.first_name = name_parts[0];
                    name_parts.splice(0, 1);
                    this.last_name = name_parts.join(' ');
                    break;
            }
        }
    }
    get name() {
        let r = `${(this.first_name) ? this.first_name : ''}${(this.last_name) ? ' ' + this.last_name : ''}`;
        ['email', 'phone'].forEach(p => {
            if (!r) {
                r = this[p];
            }
        });
        return r;
    }
    constructor(data, olMap) {
        var _a;
        super('users', 25);
        this.auth = '';
        this.contact_id = '';
        this.nick_name = '';
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone = '';
        this.company = '';
        this.zip = '';
        this.photoURL = '';
        this.signature = '';
        this.metadata = {};
        this.location_id = ''; // deprecate?
        this.tax = null; // deprecate?
        this.optin = false;
        this.role = '';
        this.type = '';
        this.online = null;
        this.unread = {};
        this.claims = {};
        this.terms = []; // Store the TOS version and date accepted.
        this.location = null;
        this.addresses = [];
        this.cards = [];
        this.devices = [];
        this._roles = {};
        this._root = false;
        this._m = ['nick_name', 'name', 'first_name', 'last_name', 'email', 'phone', 'contact_id'];
        (0, Common_1.setObjectProperties)(this, data, olMap, User);
        if (!(this.terms instanceof Array)) {
            this.terms = [];
        }
        if (this.metadata.terms && !((_a = this.terms) === null || _a === void 0 ? void 0 : _a.includes(t => t.version === this.metadata.terms.version))) {
            this.terms.push(this.metadata.terms);
            delete this.metadata.terms;
        }
        // console.log('this.terms',this.terms);
    }
    toString() {
        return `User: ${this.name || this.email || (0, Common_1.prettifyPhone)(this.phone)}`;
    }
    getAddress(type) {
        let result = null;
        if (this.addresses && this.addresses.length) {
            this.addresses.forEach((addy) => {
                if (addy.type === type && (addy.default || (!result && addy.active))) {
                    result = addy;
                }
            });
        }
        return result;
    }
    toJSON() {
        var _a;
        this.active = this.active || false;
        this.location_id = ((_a = this.location) === null || _a === void 0 ? void 0 : _a.id) || this.location_id;
        return super.toJSON();
    }
    toMiniJSON() {
        return {
            id: this.id,
            name: this.name || '',
            email: this.email || '',
            _type: this._type
        };
    }
}
exports.User = User;
index_1.olm.users = (ref, map) => {
    return new User(ref, map);
};
