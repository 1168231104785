"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filter = exports.EFilterType = exports.EFilterStatus = exports.FilterActions = exports.EFilterActions = exports.VERBS = exports.VERB_OPTIONS = exports.SUBJECTS = exports.SUBJECT_OPTIONS = void 0;
const Base_1 = require("./Base");
const Common_1 = require("./Common");
const index_1 = require("../index");
var SUBJECT_OPTIONS;
(function (SUBJECT_OPTIONS) {
    // AIRPORT = 'Airport',
    SUBJECT_OPTIONS["EMAIL"] = "Email";
    SUBJECT_OPTIONS["CONTACT"] = "Contact";
    SUBJECT_OPTIONS["CONTACT_TYPE"] = "Contact Type";
    // CONTACT_SCORE = 'Contact Score',
    SUBJECT_OPTIONS["SUBJECT"] = "Subject Line";
    SUBJECT_OPTIONS["MESSAGE"] = "Message Body";
    SUBJECT_OPTIONS["COMPANY"] = "Company";
})(SUBJECT_OPTIONS || (exports.SUBJECT_OPTIONS = SUBJECT_OPTIONS = {}));
exports.SUBJECTS = [
    // {label: SUBJECT_OPTIONS.AIRPORT, description: `Look for airport code(s) in message subject/body.`},
    { label: SUBJECT_OPTIONS.EMAIL, description: `Identify full, or any part of, the sender email (e.g. @gmail.com).` },
    // {label: SUBJECT_OPTIONS.CONTACT, description: `Look for messages from specific contact(s).`},
    { label: SUBJECT_OPTIONS.CONTACT_TYPE, description: `Look for messages from specific contact types/groups.` },
    // {
    //     label: SUBJECT_OPTIONS.CONTACT_SCORE,
    //     description: `Look for messages from contacts with targeted score(s) or ranges of scores.`
    // },
    { label: SUBJECT_OPTIONS.SUBJECT, description: `Look for keywords in subject line` },
    { label: SUBJECT_OPTIONS.MESSAGE, description: `Look for keywords in message body` }
    // ,{
    //     label: SUBJECT_OPTIONS.COMPANY,
    //     description: 'Look for emails from contacts within a specific company or set of companies.'
    // }
];
var VERB_OPTIONS;
(function (VERB_OPTIONS) {
    VERB_OPTIONS["IS"] = "is";
    VERB_OPTIONS["IS_IN"] = "is in";
    VERB_OPTIONS["IS_NOT"] = "is not";
    VERB_OPTIONS["IS_NOT_IN"] = "is not in";
    VERB_OPTIONS["IS_LESS_THAN"] = "is less than";
    VERB_OPTIONS["IS_LESS_OR_EQUAL"] = "is less than or equal to";
    VERB_OPTIONS["IS_GREATER_THAN"] = "is greater than";
    VERB_OPTIONS["IS_GREATER_OR_EQUAL"] = "is greater than or equal to";
    VERB_OPTIONS["INCLUDES"] = "includes";
    VERB_OPTIONS["EXCLUDES"] = "does not include";
})(VERB_OPTIONS || (exports.VERB_OPTIONS = VERB_OPTIONS = {}));
exports.VERBS = {};
exports.VERBS[SUBJECT_OPTIONS.EMAIL] = [VERB_OPTIONS.IS, VERB_OPTIONS.IS_IN, VERB_OPTIONS.IS_NOT_IN];
exports.VERBS[SUBJECT_OPTIONS.CONTACT] = [VERB_OPTIONS.IS, VERB_OPTIONS.IS_IN, VERB_OPTIONS.IS_NOT_IN];
exports.VERBS[SUBJECT_OPTIONS.CONTACT_TYPE] = [VERB_OPTIONS.IS, VERB_OPTIONS.IS_IN, VERB_OPTIONS.IS_NOT, VERB_OPTIONS.IS_NOT_IN];
exports.VERBS[SUBJECT_OPTIONS.SUBJECT] = [VERB_OPTIONS.IS, VERB_OPTIONS.INCLUDES, VERB_OPTIONS.EXCLUDES];
exports.VERBS[SUBJECT_OPTIONS.MESSAGE] = [VERB_OPTIONS.IS, VERB_OPTIONS.INCLUDES, VERB_OPTIONS.EXCLUDES];
exports.VERBS[SUBJECT_OPTIONS.COMPANY] = [VERB_OPTIONS.IS, VERB_OPTIONS.INCLUDES, VERB_OPTIONS.EXCLUDES];
var EFilterActions;
(function (EFilterActions) {
    EFilterActions["NOTIFY"] = "notify";
    EFilterActions["HIDE"] = "hide";
    EFilterActions["HIDE_NOTIFY"] = "hideNotify";
    EFilterActions["READ"] = "read";
})(EFilterActions || (exports.EFilterActions = EFilterActions = {}));
exports.FilterActions = [
    { label: 'Notify', value: EFilterActions.NOTIFY },
    { label: 'Hide', value: EFilterActions.HIDE },
    { label: 'Mark As Read', value: EFilterActions.READ }
];
var EFilterStatus;
(function (EFilterStatus) {
    EFilterStatus["PASS"] = "p";
    EFilterStatus["FAIL"] = "f";
    EFilterStatus["NEGATIVE"] = "n";
    EFilterStatus["NONE"] = "";
})(EFilterStatus || (exports.EFilterStatus = EFilterStatus = {}));
var EFilterType;
(function (EFilterType) {
    EFilterType["INBOX"] = "inbox";
    EFilterType["QUOTES"] = "quotes";
    EFilterType["TRIPS"] = "trips";
    EFilterType["NONE"] = "";
})(EFilterType || (exports.EFilterType = EFilterType = {}));
class Filter extends Base_1.Base {
    constructor(data, olMap) {
        super('filters', 9);
        this.name = '';
        this.type = EFilterType.INBOX;
        this.description = '';
        this.color = '';
        this.contrast = '';
        this.active = false;
        this.rules = [];
        this.followers = [];
        this.roles = [];
        this.status = EFilterStatus.NONE;
        this.action = EFilterActions.NOTIFY;
        (0, Common_1.setObjectProperties)(this, data, olMap, Filter);
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.followers = (_a = this.followers) === null || _a === void 0 ? void 0 : _a.map(recip => {
            return recip.toMinJSON ? recip.toMinJSON() : recip;
        });
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        Object.assign(r, {
            name: this.name,
            color: this.color,
            contrast: this.contrast,
            status: this.status
        });
        return r;
    }
}
exports.Filter = Filter;
index_1.olm.filters = (ref, map) => {
    return new Filter(ref, map);
};
