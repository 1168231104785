import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {ClientService} from '../../shared/_services/client.service';
import {User, Device, Address, Unread} from '@nxt/model-core';

// @ts-ignore
import {environment} from '@env/environment';


@Injectable()
export class UserService {
    user$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
    // unreads$: BehaviorSubject<Unread[]> = new BehaviorSubject<Unread[]>(null);
    devices$: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>(null);
    location$: BehaviorSubject<Address> = new BehaviorSubject<Address>(undefined);
    signature$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    roles$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    images: any = {};

    constructor(private cSvc: ClientService) {
        this.images.date = Date.now();
    }

    isRole(roles: any, label?: any): boolean {
        let failed: string;
        if (roles) {
            if (!(roles instanceof Array)) {
                roles = [roles];
            }
            for (let role of roles) {
                if (role === 'isDefault' && this.cSvc.client_id !== environment.default_client.id) {
                    failed = role;
                    break;
                } else if (role === '!isDefault' && this.cSvc.client_id === environment.default_client.id) {
                    failed = role;
                    break;
                }
            }
            if (!failed) {
                for (let role of roles) {
                    if (!role.match(/isDefault/)) {
                        let uRoles: any = this.roles$.getValue() || {};
                        uRoles = uRoles[this.cSvc.client_id] || {};
                        try {
                            let roles: any = this.roles$.getValue() || {};
                            Object.keys(roles[environment.default_client.id]).forEach(r => {
                                if (roles[environment.default_client.id][r] === true) {
                                    uRoles[r] = roles[environment.default_client.id][r]
                                }
                            });
                        } catch (e) {}
                        if (uRoles[role] || uRoles.admin) {
                            failed = null;
                            break;
                        } else {
                            failed = role;
                        }
                    }
                }
            }
        }
        return !failed;
    }
}


